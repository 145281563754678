import React, { useState } from "react";
import styles from "./navMenu.module.scss";
import logo from "../../images/logo.svg";
import { withNamespaces } from "react-i18next";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import ShoppingCartRoundedIcon from "@material-ui/icons/ShoppingCartRounded";
import NavMenuDropdownList from "./dropdownList";
import NavMenuDropdownListReduced from "./dropdownListReduced";
import englishFlag from "../../images/englishFlag.svg";
import SwapLanguage from "../swap-language/swap-language";
import {
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import germanFlag from "../../images/german-flag.svg";
import UKFlag from "../../images/UK-flag.svg";

const getRedirectURL = language => {
  if (typeof window !== "undefined") {
    const url = window.location;
    const newHost =
      language.toLowerCase() === "en" ? "www.schrott24.en" : "www.schrott24.de";
    return `${url.protocol}//${newHost}${url.pathname}${url.search}`;
  }
};

function DesktopNavMenu(props) {
  const { t, homeUrl, isReducedMenu } = props;

  const [state, setState] = useState("EN");

  const handleChange = e => {
    const value = e.target.value;
    setState(e.target.value);
    if (typeof window !== "undefined") {
      window.location.href = getRedirectURL(value);
    }
  };

  return (
    <div className={styles.navMenuWrapper}>
      <div className={styles.navMenu}>
        <div className={styles.dropdownGroup}>
          <div className={styles.logoContainer}>
            <a href={homeUrl} className={styles.metaloopLink}>
              <img className={styles.logo} src={logo} alt="Schrott logo" />
              <span className={styles.poweredByMetaloop}>
                POWERED BY METALOOP
              </span>
            </a>
          </div>
          {isReducedMenu ? (
            <NavMenuDropdownListReduced view="desktop" />
          ) : (
            <NavMenuDropdownList view="desktop" />
          )}
        </div>
        <div className={styles.accountAndFlagGroup}>
          {isReducedMenu ? (
            <div className={styles.accountGroupContainer}>
              <a
                className={styles.accountContainer}
                href="https://www.schrott24.de/partner"
              >
                <AccountCircleRoundedIcon className={styles.accountLogos} />
                <span>{t("Mein Account")}</span>
              </a>

              <div className={styles.languageSelector}>
                <SwapLanguage />
              </div>
            </div>
          ) : (
            <div
              className={`${styles.accountGroupContainerLongMenu} ${styles.accountGroupContainer}`}
            >
              <a
                className={styles.accountContainer}
                href="https://www.schrott24.de/partner"
              >
                <AccountCircleRoundedIcon className={styles.accountLogos} />
              </a>

              <a className={styles.checkoutContainer} href="/co/">
                <ShoppingCartRoundedIcon className={styles.accountLogos} />
              </a>

              <a
                className={styles.englishFlagNavContainer}
                href="https://en.schrott24.de/"
              >
                <img src={englishFlag} alt="english flag" />
                <span className={styles.languageText}>EN</span>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(DesktopNavMenu);
