import _ from "lodash";

const MAPPING_URL = [
  {
    ch: "pro",
    en: "pro",
    category: null,
  },
  {
    ch: "ueber-uns",
    en: "about-us",
    category: null,
  },
  {
    ch: "altmetall-kaufen",
    en: "buy-scrapmetal",
    category: null,
  },
  {
    ch: "schrottpreise",
    en: "scrap-prices",
    category: null,
  },
  {
    ch: "aluminiumpreis",
    en: "aluminium-price",
    category: "aluminium",
  },
  {
    ch: "bleipreise",
    en: "lead-price",
    category: "blei",
  },
  {
    ch: "edelstahlpreis",
    en: "stainless-steel-price",
    category: "edelstahl",
  },
  {
    ch: "eisenpreise",
    en: "iron-price",
    category: "eisen",
  },
  {
    ch: "stahlpreise",
    en: "steel-price",
    category: null,
  },
  {
    ch: "elektroschrottpreise",
    en: "e-waste-price",
    category: "elektronik",
  },
  {
    ch: "hartmetallpreis",
    en: "tungsten-carbide-price",
    category: "hartmetall-hss",
  },
  {
    ch: "kabelpreise",
    en: "cable-price",
    category: "kupfer-kabel",
  },
  {
    ch: "kupferpreis",
    en: "copper-price",
    category: "kupfer",
  },
  {
    ch: "messingpreise",
    en: "alloy-price",
    category: "messing",
  },
  {
    ch: "zinkpreis",
    en: "zinc-price",
    category: "zink",
  },
  {
    ch: "zinnpreis",
    en: "tin-price",
    category: "zinn",
  },
  {
    ch: "batteriepreise",
    en: "battery-price",
    category: null,
  },
  {
    ch: "marktdaten",
    en: "marktdaten",
  },
  {
    ch: "marktdaten/kupfer",
    en: "marktdaten/kupfer",
  },
  {
    ch: "marktdaten/zinn",
    en: "marktdaten/zinn",
  },
  {
    ch: "marktdaten/blei",
    en: "marktdaten/blei",
  },
  {
    ch: "marktdaten/zink",
    en: "marktdaten/zink",
  },
  {
    ch: "marktdaten/aluminium",
    en: "marktdaten/aluminium",
  },
  {
    ch: "marktdaten/Euro_MTLE",
    en: "marktdaten/Euro_MTLE",
  },
  {
    ch: "marktdaten/Euro_EZB",
    en: "marktdaten/Euro_EZB",
  },
  {
    ch: "marktdaten/Euro_FX1",
    en: "marktdaten/Euro_FX1",
  },

  {
    ch: "marktdaten/DEL_low",
    en: "marktdaten/DEL_low",
  },
  {
    ch: "marktdaten/DEL_high",
    en: "marktdaten/DEL_high",
  },
  {
    ch: "marktdaten/MK_Cu",
    en: "marktdaten/MK_Cu",
  },
  {
    ch: "marktdaten/Pb_Cable",
    en: "marktdaten/Pb_Cable",
  },
  {
    ch: "marktdaten/MB_bronze_94_6",
    en: "marktdaten/MB_bronze_94_6",
  },
  {
    ch: "marktdaten/Al_unalloyed",
    en: "marktdaten/Al_unalloyed",
  },
  {
    ch: "marktdaten/Al_cables",
    en: "marktdaten/Al_cables",
  },
  {
    ch: "marktdaten/MB_MS_58_1",
    en: "marktdaten/MB_MS_58_1",
  },
  {
    ch: "marktdaten/MB_MS_58_2",
    en: "marktdaten/MB_MS_58_2",
  },
  {
    ch: "marktdaten/MB_MP_58",
    en: "marktdaten/MB_MP_58",
  },
  {
    ch: "marktdaten/MB_MS_63_wire",
    en: "marktdaten/MB_MS_63_wire",
  },
  {
    ch: "marktdaten/MB_MS_63_37",
    en: "marktdaten/MB_MS_63_37",
  },
  {
    ch: "marktdaten/disclaimer",
    en: "marktdaten/disclaimer",
  },
];

const basePath = {
  qa: {
    ch: "http://qa.schrott24.ch/",
    en: "http://qa.en.schrott24.de/",
  },
  live: {
    ch: "http://www.schrott24.ch/",
    en: "https://en.schrott24.de/",
  },
};

export const getUrl = (domain, key) => {
  const environment = window.location.href.includes("qa.") ? "qa" : "live";

  /* Pro is special case because we move it inside of metaloop domain*/

  if (key === "pro") { 
    var metaloopPath = "https://www.metaloop.com";
    if (environment === "qa") {
      metaloopPath = "https://qa.metaloop.com";
    } 
    return metaloopPath + "/pro?lng=" + (domain === "en" ? "en" : "de");
  }

  var url = "";

  for (var i = 0; i < MAPPING_URL.length; i++) {
    if (MAPPING_URL[i]["ch"] === key || MAPPING_URL[i]["en"] === key) {
      url = MAPPING_URL[i][domain];
      break;
    }
  }

  return basePath[environment][domain] + url;
};

export const getCategoryPath = category => {
  const path = _.find(MAPPING_URL, cp => cp.category === category);
  if (path) {
    return path.ch;
  }
  return "";
};