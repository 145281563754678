import React, { useContext } from "react";
import { withNamespaces } from "react-i18next";
import footerStyles from "./footer.module.scss";
import styles from "./linksSection.module.scss";

import DomainContext from "../../../../context/DomainContext";
import { getUrl } from "../../../../utils/urlMap";

import SocialMediaGroup from "./socialMediaGroup.js";
import { StaticImage } from "gatsby-plugin-image";

const LinksSection = props => {
  const domain = useContext(DomainContext);
  const { t } = props;

  return (
    <>
      <div
        className={[footerStyles.footer, styles.footerLinksContainer].join(" ")}
      >
        <div
          className={[styles.footerLinks, styles.firstPartFooterLinks].join(
            " "
          )}
        >
          <div className={styles.columnEuro}>
            <StaticImage
              src={"../../../../images/europe.png"}
              alt="European flag"
            />
            <p>
              {t(
                "Dieses Projekt wurde im Rahmen der Finanzhilfevereinbarung Nr. 816314 und 873076 aus den Forschungs- und Innovationsprogrammen der Europäischen Union für Horizont 2020 finanziert."
              )}
            </p>

            <StaticImage
              src={"../../../../images/ekomiWithStars.png"}
              alt="Ekomi logo"
              className={styles.ekomiLogo}
            />
          </div>

          <div
            className={[styles.columnUnternehmen, styles.unternehmen].join(" ")}
          >
            <div>
              <span className={styles.sectionTitle}>{t("Unternehmen")}</span>
              <div className={styles.twoColumnsOfLinks}>
                <div className={styles.listOfLinks}>
                  <a href={getUrl(domain, "ueber-uns")}>{t("Über uns")}</a>{" "}
                  <a href="https://www.schrott24.de/support/">
                    {t("So funktioniert's")}
                  </a>{" "}
                  <a href="https://www.schrott24.de/karriere/">
                    {t("Karriere")}
                  </a>{" "}
                  <a href="https://www.schrott24.de/presse/">{t("Presse")}</a>
                </div>
                <div className={styles.listOfLinks}>
                  <a href="https://www.schrott24.de/blog/">{t("Blog")}</a>{" "}
                  <a href="https://www.schrott24.de/nachhaltigkeit/">
                    {t("Nachhaltigkeit")}
                  </a>{" "}
                  <a href="https://www.schrott24.de/standorte/">
                    {t("Standorte")}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.columnServices}>
            <span className={styles.sectionTitle}>{t("Services")}</span>

            <div className={styles.listOfLinks}>
              <a href="https://www.schrott24.de/demontage">
                {t("Industrierückbau")} &amp; {t("Demontagen")}{" "}
              </a>
              <a href="https://www.schrott24.de/kraftwerkdemontage">
                {t("Kraftwerksrückbau")}
              </a>{" "}
              <a href="https://www.schrott24.de/windkraftwerkdemontage">
                {t("Demontage von Windkraftanlagen")}{" "}
              </a>
              <a href="https://www.schrott24.de/partner-werden/">
                {t("Partner Webshop")}
              </a>
            </div>
          </div>

          <div
            className={[footerStyles.tablet, styles.socialMediaContainer].join(
              " "
            )}
          >
            <SocialMediaGroup showFlag={true} />
          </div>
        </div>

        <div
          className={[styles.footerLinks, styles.secondPartFooterLinks].join(
            " "
          )}
        >
          <div className={footerStyles.desktop}>
            <SocialMediaGroup showFlag={true} />
          </div>

          <div
            className={[styles.columnUnternehmen, styles.hideInPhone].join(" ")}
          >
            <span className={styles.sectionTitle}>{t("Schrottpreise")}</span>
            <div className={styles.twoColumnsOfLinks}>
              <div className={styles.listOfLinks}>
                <a href={getUrl(domain, "schrottpreise")}>
                  {t("Schrottpreise")}
                </a>
                <a href={getUrl(domain, "hartmetallpreis")}>
                  {t("Hartmetallpreis")}
                </a>
                <a href={getUrl(domain, "edelstahlpreis")}>
                  {t("Edelstahlpreis")}
                </a>
                <a href={getUrl(domain, "zinkpreis")}>{t("Zinkpreis")}</a>
              </div>
              <div className={styles.listOfLinks}>
                <a href={getUrl(domain, "messingpreise")}>
                  {t("Messingpreis")}
                </a>
                <a href={getUrl(domain, "kabelpreise")}>{t("Kabelpreis")} </a>
                <a href={getUrl(domain, "aluminiumpreis")}>
                  {t("Aluminiumpreis")}
                </a>
                <a href={getUrl(domain, "elektroschrottpreise")}>
                  {t("E-Schrottpreis")}
                </a>
              </div>
            </div>
          </div>

          <div
            className={[styles.columnServices, styles.hideInPhone].join(" ")}
          >
            <span className={styles.sectionTitle}>&nbsp;</span>
            <div className={styles.twoColumnsOfLinks}>
              <div className={styles.listOfLinks}>
                <a href={getUrl(domain, "bleipreise")}>{t("Bleipreis")}</a>
                <a href={getUrl(domain, "zinnpreis")}>{t("Zinnpreis")}</a>
                <a href={getUrl(domain, "kupferpreis")}>{t("Kupferpreis")}</a>
                <a href={getUrl(domain, "eisenpreise")}>{t("Eisenpreis")}</a>
              </div>
              <div className={styles.listOfLinks}>
                <a href={getUrl(domain, "stahlpreise")}>{t("Stahlpreis")}</a>
                <a href={getUrl(domain, "batteriepreise")}>
                  {t("Batteriepreis")}
                </a>
              </div>
            </div>
          </div>

          <div
            className={[styles.columnUnternehmen, styles.showInPhone].join(" ")}
          >
            <span className={styles.sectionTitle}>{t("Schrottpreise")}</span>
            <div className={styles.twoColumnsOfLinks}>
              <div className={styles.listOfLinks}>
                <a href={getUrl(domain, "schrottpreise")}>
                  {t("Schrottpreise")}
                </a>
                <a href={getUrl(domain, "hartmetallpreis")}>
                  {t("Hartmetallpreis")}
                </a>
                <a href={getUrl(domain, "edelstahlpreis")}>
                  {t("Edelstahlpreis")}
                </a>
                <a href={getUrl(domain, "zinkpreis")}>{t("Zinkpreis")}</a>
                <a href={getUrl(domain, "messingpreise")}>
                  {t("Messingpreis")}
                </a>
                <a href={getUrl(domain, "kabelpreise")}>{t("Kabelpreis")} </a>
                <a href={getUrl(domain, "aluminiumpreis")}>
                  {t("Aluminiumpreis")}
                </a>
                <a href={getUrl(domain, "elektroschrottpreise")}>
                  {t("E-Schrottpreis")}
                </a>
              </div>

              <div className={styles.listOfLinks}>
                <a href={getUrl(domain, "bleipreise")}>{t("Bleipreis")}</a>
                <a href={getUrl(domain, "zinnpreis")}>{t("Zinnpreis")}</a>
                <a href={getUrl(domain, "kupferpreis")}>{t("Kupferpreis")}</a>
                <a href={getUrl(domain, "eisenpreise")}>{t("Eisenpreis")}</a>
                <a href={getUrl(domain, "stahlpreise")}>{t("Stahlpreis")}</a>
                <a href={getUrl(domain, "batteriepreise")}>
                  {t("Batteriepreis")}
                </a>
              </div>
            </div>
          </div>

          <div className={footerStyles.mobile}>
            <SocialMediaGroup showFlag={true} />
          </div>
        </div>
      </div>
    </>
  );
};

export default withNamespaces()(LinksSection);
