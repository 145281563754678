import React, { useContext } from "react";
import { withNamespaces } from "react-i18next";
import DomainContext from "../../../../context/DomainContext";

import AwardsSection from "./awardsSection";
import LogoSection from "./logoSection";
import LinksSection from "./linksSection";
import LinksSectionReduced from "./linksSectionReduced";
const Footer = props => {
  const domain = useContext(DomainContext);

  return (
    <div>
      <AwardsSection />
      <LogoSection />
      {domain === "ch" || domain === "en" ? (
        <LinksSectionReduced />
      ) : (
        <LinksSection />
      )}
    </div>
  );
};

export default withNamespaces()(Footer);
