import React from "react";
import { withNamespaces } from "react-i18next";

import SwapLanguage from "../../../swap-language/swap-language";
import styles from "./linksSection.module.scss";

import englishFlag from "../../../../images/englishFlag.svg";

import { StaticImage } from "gatsby-plugin-image";

class SocialMediaGroup extends React.Component {
  render() {
    const { t, showFlag } = this.props;

    return (
      <>
        <div className={[styles.columnEuro, styles.officeTimes].join(" ")}>
          <span className={styles.sectionTitle}>Office info</span>
          <div className={styles.timesContainer}>
            <h4>08:00 – 17:00 (Mo–Do)</h4>
            <h4>08:00 – 15:00 (Fr)</h4>
            <h4>
              <a href="/sitemap">Sitemap</a>
            </h4>
          </div>
          <div className={styles.logoWrapper}>
            <a href="https://www.linkedin.com/company/schrott-24/">
              <div className={styles.logoContainer}>
                <StaticImage
                  src={"../../../../images/linkedin.png"}
                  alt="Linkedin logo"
                />
              </div>
            </a>
            <a href="https://www.facebook.com/schrott24/">
              <div className={styles.logoContainer}>
                <StaticImage
                  src={"../../../../images/facebook.png"}
                  alt="Facebook logo"
                />
              </div>
            </a>
            <a href="https://twitter.com/schrott24">
              <div className={styles.logoContainer}>
                <StaticImage
                  src={"../../../../images/twitter.png"}
                  alt="Twitter logo"
                />
              </div>
            </a>
            <a href="https://www.youtube.com/channel/UCrIvaKJkiu_V8EQMS8yBv2Q">
              <div className={styles.logoContainer}>
                <StaticImage
                  src={"../../../../images/youtube.png"}
                  alt="Youtube logo"
                />
              </div>
            </a>
          </div>
          {showFlag === true ? (
            <div className={styles.swapLanguageContainer}>
              <SwapLanguage longText={true} />
            </div>
          ) : null}

          
        </div>
      </>
    );
  }
}

export default withNamespaces()(SocialMediaGroup);
