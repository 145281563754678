import React from "react";
import styles from "./navMenu.module.scss";
import { withNamespaces } from "react-i18next";
import SwapLanguage from "../swap-language/swap-language";
import MenuIcon from "@material-ui/icons/Menu";
import ShoppingCartRoundedIcon from "@material-ui/icons/ShoppingCartRounded";
import CloseIcon from "@material-ui/icons/Close";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import logo from "../../images/logo.svg";
import NavMenuDropdownList from "./dropdownList";
import NavMenuDropdownListReduced from "./dropdownListReduced";

class MobileNavMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }
  render() {
    const { t, homeUrl, isReducedMenu } = this.props;
    return (
      <div className={styles.navMobileContainer}>
        <div className={styles.navMenuMobile}>
          <div className={styles.logoContainer}>
            <a href={homeUrl} className={styles.metaloopLink}>
              <img className={styles.logo} src={logo} alt="Schrott logo" />
              <span className={styles.poweredByMetaloop}>
                POWERED BY METALOOP
              </span>
            </a>
          </div>

          <div
            className={`${styles.accountGroupContainer} ${
              isReducedMenu ? null : styles.accountGroupContainerTwoIcons
            }  `}
          >
            {isReducedMenu ? null : (
              <a className={styles.checkoutContainer} href="/co/">
                <ShoppingCartRoundedIcon className={styles.accountLogos} />
              </a>
            )}

            <div className={styles.accountContainer}>
              <MenuIcon
                className={styles.accountLogos}
                onClick={() => this.onClick()}
              />
            </div>
          </div>
        </div>
        {this.state.menuOpen ? (
          <div className={styles.mobileMenuWrapper}>
            <div className={styles.modalHeader}>
              <span className={styles.menuModalTitle}>{t("Menü")}</span>

              <CloseIcon
                className={styles.closeIcon}
                onClick={() => this.onClick()}
              />
            </div>

            <div className={styles.menuGroup}>
              <div className={styles.navMenuDropdownContainer}>
                {isReducedMenu ? (
                  <NavMenuDropdownListReduced view="mobile" />
                ) : (
                  <NavMenuDropdownList view="mobile" />
                )}
                {isReducedMenu ? null : (
                  <div className={styles.dropdownLabelContainer}>
                    <a
                      className={styles.singleLink}
                      href={homeUrl + "/faq/karriere"}
                    >
                      {t("Karriere")}
                    </a>
                  </div>
                )}
              </div>
              <div className={styles.modalFooter}>
                <a
                  className={styles.myAccount}
                  href="https://www.schrott24.de/partner"
                >
                  <AccountCircleRoundedIcon className={styles.accountLogos} />
                  <span className={styles.myAccountText}>
                    {t("Mein Account")}
                  </span>
                </a>
                {isReducedMenu ? (
                  <div className={styles.languageSelectorMobile}>
                    <SwapLanguage longText={true} />
                  </div>
                ) : (
                  <a className={styles.checkoutContainer} href="/co/">
                    <ShoppingCartRoundedIcon className={styles.accountLogos} />
                    <span>{t("Warenkorb")}</span>
                  </a>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withNamespaces()(MobileNavMenu);
