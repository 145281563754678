import React, { useEffect ,useContext, useState} from "react";

import { withNamespaces } from "react-i18next";
import DomainContext from "../../context/DomainContext";
import DesktopNavMenu from "./desktopNavMenu";
import MobileNavMenu from "./mobileNavMenu";
 
  const NavMenu = (props) => {
    const [homeUrl, setHomeUrl] = useState("");
 
    const domain = useContext(DomainContext);
    useEffect(() => {  
      setHomeUrl(window.location.origin);
    });


 
    return (
      <nav>
        <DesktopNavMenu homeUrl={homeUrl} isReducedMenu={domain==="ch"||domain=="en"} />
        <MobileNavMenu homeUrl={homeUrl} isReducedMenu={domain==="ch"||domain=="en"} />
      </nav>
    );
 
}

export default withNamespaces()(NavMenu);
