import React, { useEffect, useContext, useState } from "react";
import DomainContext from "../../context/DomainContext";
import germanFlag from "../../images/german-flag.svg";
import UKFlag from "../../images/UK-flag.svg";
import styles from "./languageSwitch.module.scss";
import { Link } from "gatsby";
import { getUrl } from "../../utils/urlMap";

const SwapLanguage = props => {
  const domain = useContext(DomainContext);

  /*By default redirect to home*/

  const [redirectionUrl, setRedirectionUrl] = useState("");

  useEffect(() => {
    var arrUrl = window.location.href.split("/");

    if (arrUrl[arrUrl.length - 1].length > 0) {
      var key = arrUrl.slice(3);
      if (key[key.length - 1] === "") {
        key.pop();
      }
      key = key.join("/");
    } else {
      var key = arrUrl[arrUrl.length - 2];
    }

    setRedirectionUrl(getUrl(domain === "en" ? "ch" : "en", key));
  });

  let flag = UKFlag;
  let alt = "english flag";
  let text = "English";
  let shortText = "EN";
  if (domain === "en") {
    flag = germanFlag;
    alt = "german flag";
    text = "German";
    shortText = "DE";
  }

  return (
    <>
      <Link to={redirectionUrl} className={styles.link}>
        <img src={flag} alt={alt} />
        <span className={styles.languageText}>
          {props.longText ? text : shortText}
        </span>
      </Link>
    </>
  );
};

export default SwapLanguage;
