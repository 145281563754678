import React from "react";
import { withNamespaces } from "react-i18next";
import footerStyles from "./footer.module.scss";
import styles from "./logoSection.module.scss";
import eu from "../../../../images/eu_blue.png";
import aws from "../../../../images/aws_blue.png";
import sfg from "../../../../images/sfg_blue.png";
import ffg from "../../../../images/ffg_blue.png";
import greenTech from "../../../../images/greenTech_blue.png";
import eden from "../../../../images/eden_blue.png";

import { StaticImage } from "gatsby-plugin-image";

import rawMaterial from "../../../../images/logo_raw_material_footer.png";

class LogoSection extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <div className={styles.darkBlue}>
          <div
            className={[
              styles.logoContainer,
              footerStyles.desktop,
              footerStyles.tablet,
            ].join(" ")}
          >
            <StaticImage
              src={"../../../../images/eu_blue.png"}
              alt="European commission logo"
              className={styles.logoImage}
            />
            <StaticImage
              src={"../../../../images/aws_blue.png"}
              alt="AWS logo"
              className={styles.logoImage}
            />
            <StaticImage
              src={"../../../../images/sfg_blue.png"}
              alt="SFG logo"
              className={styles.logoImage}
            />
            <StaticImage
              src={"../../../../images/ffg_blue.png"}
              alt="FFG logo"
              className={styles.logoImage}
            />
            <StaticImage
              src={"../../../../images/greenTech_blue.png"}
              alt="GreenTech logo"
              className={styles.logoImage}
            />
            <StaticImage
              src={"../../../../images/eden_blue.png"}
              alt="Eden logo"
              className={styles.logoImage}
            />
            <StaticImage
              src={"../../../../images/logo_raw_material_footer.png"}
              alt="Raw material logo"
              className={styles.logoImage}
            />
          </div>

          <div
            className={[footerStyles.mobile, styles.logoContainer].join(" ")}
          >
            <div className={styles.logoWrapperGroup}>
              <div className={styles.logoWrapper}>
                <StaticImage
                  src={"../../../../images/eu_blue.png"}
                  alt="European commission logo"
                  className={styles.logoImage}
                />
                <StaticImage
                  src={"../../../../images/aws_blue.png"}
                  alt="AWS logo"
                  className={styles.logoImage}
                />
                <StaticImage
                  src={"../../../../images/sfg_blue.png"}
                  alt="SFG logo"
                  className={styles.logoImage}
                />
              </div>
              <div className={styles.logoWrapper}>
                <StaticImage
                  src={"../../../../images/ffg_blue.png"}
                  alt="FFG logo"
                  className={styles.logoImage}
                />
                <StaticImage
                  src={"../../../../images/greenTech_blue.png"}
                  alt="GreenTech logo"
                  className={styles.logoImage}
                />
                <StaticImage
                  src={"../../../../images/eden_blue.png"}
                  alt="Eden logo"
                  className={styles.logoImage}
                />
              </div>

              <div
                className={[styles.centerElement, styles.logoWrapper].join(" ")}
              >
                <StaticImage
                  src={"../../../../images/logo_raw_material_footer.png"}
                  alt="Raw material logo"
                  className={styles.logoImage}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withNamespaces()(LogoSection);
