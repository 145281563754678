 import React, { useEffect,useState } from "react"; 
 

import { withNamespaces } from "react-i18next";
import Dropdown from "./dropdown";


const NavMenuDropdownList = (props) => { 
  const [openKey, setOpenKey] = useState("");
  const [host, setHost] = useState("");
  const updateKey = (value) => {
    setOpenKey(value);
  }

 
  useEffect(() => {
    setHost(typeof window !== 'undefined' ?window.location.protocol + "//" + window.location.hostname  : '');  

  }, []);
    const { t } = props; 
    return (
      <>
        <Dropdown
          openKey={openKey}
          updateKey={updateKey}
          view={props.view}
          key={"schrottpreise"}
          element={"schrottpreise"}
          dropdownLabel={"Schrottpreise"}
          linkList={[
            {
              url: "/altmetall-ankauf/",
              label: t("Altmetall Ankauf"),
            },

            {
              url: "/altmetall-ankauf/aluminium/",
              label: t("Aluminium"),
            },
            {
              url: "/altmetall-ankauf/blei/",
              label: t("Blei"),
            },

            {
              url: "/altmetall-ankauf/edelstahl/",
              label: t("Edelstahl"),
            },
            {
              url: "/altmetall-ankauf/eisen-und-stahl/",
              label: t("Eisen und Stahl"),
            },
            {
              url: "/altmetall-ankauf/elektronik/",
              label: t("Elektroschrott"),
            },
            {
              url: "/altmetall-ankauf/hartmetall-hss/",
              label: t("Hartmetall und HSS"),
            },

            {
              url: "/altmetall-ankauf/kupfer-kabel/",
              label: t("Kabel"),
            },
            {
              url: "/altmetall-ankauf/kupfer/",
              label: t("Kupfer"),
            },
            {
              url: "/altmetall-ankauf/messing/",
              label: t("Messing"),
            },
            {
              url: "/altmetall-ankauf/legierungen-cu-ni/",
              label: t("Rotguss/Legierunge"),
            },
            {
              url: "/altmetall-ankauf/zink/",
              label: t("Zink"),
            },
            {
              url: "/altmetall-ankauf/zinn/",
              label: t("Zinn"),
            },
          ]}
        />

        <Dropdown
          openKey={openKey}
          updateKey={updateKey}
          view={props.view}
          key={"marktdaten"}
          element={"marktdaten"}
          dropdownLabel={t("Marktdaten")}
          linkList={[          
            
            {
              url: host + "/marktdaten/",
              label: t("Übersicht"),
            },

            {
              url: host + "/marktdaten/kupfer/",
              label: t("Kupfer"),
            },

            {
              url: host + "/marktdaten/blei/",
              label: t("Blei"),
            },

            {
              url: host + "/marktdaten/aluminium/",
              label: t("Aluminium"),
            },

            {
              url: host + "/marktdaten/zinn/",
              label: t("Zinn"),
            },  {
              url: host + "/marktdaten/zink/",
              label: t("Zink"),
            },
          ]}
        />

        <Dropdown
          openKey={openKey}
          updateKey={updateKey}
          view={props.view}
          key={"verkaufen"}
          element={"verkaufen"}
          dropdownLabel={t("Verkaufen")}
          linkList={[
            {
              url: "/pro/",
              label: t("Altmetallpreis anfragen"),
            },

            {
              url: "/altmetall-ankauf/",
              label: t("Kleine Mengen verkaufen"),
            },
          ]}
        />

        <Dropdown
          openKey={openKey}
          updateKey={updateKey}
          view={props.view}
          key={"kaufen"}
          element={"kaufen"}
          dropdownLabel={t("Kaufen")}
          linkList={[
            {
              url: "/altmetall-kaufen/",
              label: t("Altmetall kaufen"),
            },

            {
              url: "/partner/",
              label: t("Schrott24 Partner werden"),
            },
            {
              url: "/altmetall-kaufen/partner/partnerwebshop/",
              label: t("Ihr Webshop"),
            },
          ]}
        />

        <Dropdown
          openKey={openKey}
          updateKey={updateKey}
          view={props.view}
          key={"kunden"}
          element={"kunden"}
          dropdownLabel={t("Kunden")}
          linkList={[
            {
              url: "/pro/",
              label: t("Industrie"),
            },

            {
              url: "/altmetall-ankauf/",
              label: t("Gewerbe/Handwerk"),
            },
            {
              url: "/altmetall-kaufen/",
              label: t("Endverbraucher (Schmelzen, Hütten, etc.)"),
            },

            {
              url: "/altmetall-kaufen/partner-werden/",
              label: t("Altmetallhändler"),
            },
            {
              url: "/altmetall-ankauf/",
              label: t("Privat"),
            },

       
          ]}
        />

        <Dropdown
          openKey={openKey}
          updateKey={updateKey}
          view={props.view}
          key={"uber-uns"}
          element={"uber-uns"}
          dropdownLabel={t("Über uns")}
          linkList={[
            {
              url: "/faq/support/",
              label: t("So funktioniert's"),
            },

            {
              url: "/standorte/",
              label: t("Standorte"),
            },
            {
              url: "/faq/presse/",
              label: t("Presse"),
            },

            {
              url: "/blog/",
              label: t("Blog"),
            },
            {
              url: "/faq/ueber-uns/",
              label: t("Über uns"),
            },
            {
              url: "/faq/karriere/",
              label: t("Karriere"),
            },
          ]}
        />
      </>
    );
  }
 

export default withNamespaces()(NavMenuDropdownList);
