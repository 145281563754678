import React, {  useContext,useEffect, useState } from "react";


import DomainContext from "../../context/DomainContext"; 
import { withNamespaces } from "react-i18next";
import styles from "./navMenu.module.scss";
import Dropdown from "./dropdown";
import { getUrl } from "../../utils/urlMap";
 
   const NavMenuDropdownList = (props) => {
    const domain = useContext(DomainContext);
    const [host, setHost] = useState(""); 
    const [openKey, setOpenKey] = useState("");


    useEffect(() => {
      setHost(typeof window !== 'undefined' ?window.location.protocol + "//" + window.location.hostname  : '');  
  
    }, []);
    const { t } = props;
    return (
      <>
        <div className={styles.dropdownLabelContainer}>
          <a className={styles.navLink} href={ getUrl(domain,"pro")}>
            {t("Verkaufen")}
          </a>
        </div> 
        <div className={styles.dropdownLabelContainer}>
          <a className={styles.navLink} href={ getUrl(domain,"altmetall-kaufen")}>
            {t("Kaufen")}
          </a>
        </div>

        
        <Dropdown
          openKey={openKey}
          updateKey={setOpenKey}
          view={props.view}
          key={"Schrottpreise"}
          element={"Schrottpreise"}
          dropdownLabel={t("Schrottpreise")}
          linkList={[
            {
              url: getUrl(domain,"schrottpreise"),
              label: t("Schrottpreise"),
            },

            {
              url: getUrl(domain,"aluminiumpreis"),
              label: t("Aluminiumpreis"),
            },
            {
              url: getUrl(domain,"bleipreise"),
              label: t("Bleipreis"),
            },

            {
              url: getUrl(domain,"edelstahlpreis"),
              label: t("Edelstahlpreis"),
            },
            {
              url: getUrl(domain,"eisenpreise"),
              label: t("Eisenpreis"),
            },
            {
              url: getUrl(domain,"stahlpreise"),
              label: t("Stahlpreis"),
            },
            {
              url: getUrl(domain,"elektroschrottpreise"),
              label: t("Elektroschrottpreis"),
            },

            {
              url: getUrl(domain,"hartmetallpreis"),
              label: t("Hartmetallpreis"),
            },
            {
              url: getUrl(domain,"kabelpreise"),
              label: t("Kabelpreis"),
            },
            {
              url: getUrl(domain,"kupferpreis"),
              label: t("Kupferpreis"),
            },
            {
              url: getUrl(domain,"messingpreise"),
              label: t("Messingpreis"),
            },
            {
              url:  getUrl(domain,"zinkpreis"),
              label: t("Zinkpreis"),
            },
            {
              url:  getUrl(domain,"zinnpreis"),
              label: t("Zinnpreis"),
            },
          ]}
        />






<Dropdown
          openKey={openKey}
          updateKey={setOpenKey}
          view={props.view}
          key={"marktdaten"}
          element={"marktdaten"}
          dropdownLabel={t("Marktdaten")}
          linkList={[          
            
            {
              url: host + "/marktdaten/",
              label: t("Übersicht"),
            },

            {
              url: host + "/marktdaten/kupfer/",
              label: t("Kupfer"),
            },

            {
              url: host + "/marktdaten/blei/",
              label: t("Blei"),
            },

            {
              url: host + "/marktdaten/aluminium/",
              label: t("Aluminium"),
            },

            {
              url: host + "/marktdaten/zinn/",
              label: t("Zinn"),
            },  {
              url: host + "/marktdaten/zink/",
              label: t("Zink"),
            },
          ]}
        />
        <div className={styles.dropdownLabelContainer}>
          <a className={styles.navLink} href={ getUrl(domain,"ueber-uns")}>
            {t("Über uns")}
          </a>
        </div>
      </>
    );
  }
  
export default withNamespaces()(NavMenuDropdownList);
